'use client'
import StyledComponentsRegistry from "@/lib/registry";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";
import GlobalStyle from "@/styles/globalStyle";
import FramerMotion from "@/components/FramerMotion";
import SmoothScroll from "@/components/SmoothScroll";
import "/app/global.css";
import {usePathname} from "next/navigation";
import {RevealAnimation} from "@/components/animations/SectionReveal";
import {Parallax} from "@/components/animations/Parallax";
import {SplitUp} from "@/components/animations/TextAnimation";
import {useEffect, useState} from "react";
import Footer from "@/components/Footer";
import Menu from "@/components/Menu";
import FontPreloader from "@/FontPreloader";
import PageTransition from "@/components/PageTransition";
import HasprCursor from 'haspr-cursor' // Import Wrapper
import 'haspr-cursor/dist/cursor.css' // Import Style sheet


export default function RootLayout({children}) {
    const location = usePathname();
    const [menuColor, setMenuColor] = useState('');
    useEffect(() => {
        // Update menu color based on the current route
        if (
            location === '/about-us'
            || location === '/contact'
            || location === '/product-details'
            || location === '/product-listing'
            || location === '/product-enquiry'
            // || location.pathname.startsWith(`/dealer-list`)
        ) {
            setMenuColor('#191D1C');
        } else {
            setMenuColor('#F1F0EE');
        }
    }, [location]);

    RevealAnimation();
    Parallax();
    SplitUp();


    return (
        <html lang="en">
        <head>
            <link rel="icon" type="image/png" href="/images/static/fav.png"/>
            <meta content="#000000" name="theme-color"/>
            <link href="https://bhl-ten.vercel.app/" rel="canonical" hrefLang="en"/>
            <title>BHL - Better Tiles, The Perfect style</title>
            <FontPreloader/>
        </head>
        <body>
        <StyledComponentsRegistry>
            <HasprCursor>
                {location === '/' && <PageTransition/>}
                <GlobalStyle/>
                <ToastContainer/>
                <FramerMotion>
                    <Menu color={menuColor}/>
                    <SmoothScroll>
                        {children}
                        <Footer/>
                    </SmoothScroll>
                </FramerMotion>
            </HasprCursor>

        </StyledComponentsRegistry>
        </body>
        </html>
    );
}
