
export async function getAllproductApi(param) {
    const response = await fetch(
        'https://project.bestinbd.com/2408BHL/api/get-req-data/sections?type=slug&value=products-listing&get_section=yes&image=yes&post=yes&file=no&gallery=no',
        {
            cache: "no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}

    export async function getAllproductDetailsApi(param) {
        const response = await fetch(
            `https://project.bestinbd.com/2408BHL/api/get-req-data/product-data?type=slug&value=${param}&image=yes&post=yes&file=yes&specification=yes&gallery=yes&variation=yes`,
            {
                cache: "no-store"
            }
        );
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    }


    //search products
export async function getSearchProducts() {
    const response = await fetch(
        `https://project.bestinbd.com/2408BHL/api/get-req-data/all-products?image=noe&post=noe&file=noe&specification=noe&gallery=noe&variation=noe&limit=`,
        {
            cache: "no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}

//Menu Brands
export async function getMenuBrands() {
    const response = await fetch(
        `https://project.bestinbd.com/2408BHL/api/get-req-data/page-post-data?page_id=29&type=page&value&limit`,
        {
            cache: "no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}