
export async function getHomeApi(param) {
    const response = await fetch(
        `https://project.bestinbd.com/2408BHL/api/get-req-data/sections?type=slug&value=${param}&get_section=yes&image=yes&post=yes&file=no&gallery=no`,
        {
            cache: "no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}
//Blog Listing
export async function getBlogListApi() {
    const response = await fetch(
        `https://project.bestinbd.com/2408BHL/api/get-req-data/child-pages?page_id=25&image=yes&post=no&file=no&gallery=no&sections=no`,
        {
            cache: "no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}
//Blog Details
export async function getBlogDetailsApi(param) {
    const response = await fetch(
        `https://project.bestinbd.com/2408BHL/api/get-req-data/page-data?type=slug&value=${param}&image=yes&post=yes&file=no&gallery=no`,
        {
            cache: "no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}


